<section id="servicios" class="section-wrap services pb-100  bg-light">
    <div class="container">
        <!-- <h2 class="text-center bottom-line"><%- __('home.nuestros_servicios.title')%></h2> -->
        <span class="titulo text-center bottom-line mb-30">SERVICIOS</span>
        <p>Nuestros servicios más destacados en Instalaciones domesticas son los siguientes:</p>
        <div class="row div-servicios">
            <div class="card-icon col-12 col-md-6">
                <app-card-servicios-icon
                    [icon]="'fireplace'" 
                    [title]="'Instalación de gas'" 
                    [description]="'Instalación individuales de gas, para viviendas unifamiliares, pisos, comunidades de vecinos, locales…'">
                </app-card-servicios-icon>
            </div>
     
            <div class="card-icon col-12 col-md-6">
                <app-card-servicios-icon
                [icon]="'sync_problem'" 
                [title]="'Corrección de defectos'" 
                [description]="'Ofrecemos corrección de defectos'">
                </app-card-servicios-icon>
            </div>

            <div class="card-icon col-12 col-md-6">
                <app-card-servicios-icon
                [icon]="'workspace_premium'" 
                [title]="'Certificaciones'" 
                [description]="'Certificaciones para nuevo alta de suministro'">
                </app-card-servicios-icon>
            </div>

            <div class="card-icon col-12 col-md-6">
                <app-card-servicios-icon
                [icon]="'query_stats'" 
                [title]="'Análisis de combustión'" 
                [description]="'Hacemos análisis de combustión de aparatos a gas'">
                </app-card-servicios-icon>
            </div>

            <div class="card-icon col-12 col-md-6">
                <app-card-servicios-icon
                [icon]="'emergency_heat'" 
                [title]="'Reparación de fugas'" 
                [description]="'Localización y reparación de fugas'">
                </app-card-servicios-icon>
            </div>
            
            <div class="card-icon col-12 col-md-6">
                <app-card-servicios-icon
                [icon]="'recycling'" 
                [title]="'Sustitución de componentes'" 
                [description]="'Sustitución de calderas, calentadores, reguladores de finca, encimeras y hornos a gas.'">
                </app-card-servicios-icon>
            </div>

            <div class="card-icon col-12 col-md-6">
                <app-card-servicios-icon
                [icon]="'build_circle'" 
                [title]="'Servicios de reparación'" 
                [description]="'Ofrecemos reparaciones rápidas y efectivas de calderas y calentadores'">
                </app-card-servicios-icon>
            </div>

            <div class="card-icon col-12 col-md-6">
                <app-card-servicios-icon
                [icon]="'warning'" 
                [title]="'Servicios de emergencia'" 
                [description]="'Servicio de Emergencia de gas para Comunidades y particulares'">
                </app-card-servicios-icon>
            </div>
        </div>
    </div>
</section>