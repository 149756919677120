<section class="section-wrap" >
    <div class="overlay"></div>
    <!-- <section class="section-wrap" data-bg="/assets/img/bosque-iberdrola.jpg" style="background-attachment: scroll;"> -->
    <div class="container-fluid nopadding relative text-center divIberdrola"    style="position: relative; width: 100%; height: 100%;">
         <!-- <img class="imagenIberdrola" src="assets/img/iberdrolaPartner.png" alt="Iberdrola" style="width: 100%; height: 100%; object-fit: cover; filter: brightness(0.5);">  -->
        <!-- <h6 class="color-white mt-20"><%- __('global.ver_video')%></h6> -->
        <div class="textoIberdrola"style="position: absolute; top: 40%; left: 50%; transform: translate(-50%, -50%); z-index: 2; color: white;">
            <span  class="textoIberdrola"> Actualmente,
                <h1 class="iberdrola-text-h1 nocaps">
                somos socio colaborador con
                </h1 >
                <a href="https://www.iberdrola.com" target="_blank" rel="noopener noreferrer"><h1 class="iberdrola-text-enlace nocaps">Iberdrola</h1></a>, 
            <br  class="textoIberdrola">lo que nos permite ofrecer soluciones energéticas innovadoras y eficientes, tanto a empresas como a clientes particulares.</span>
        </div>
    </div>
</section>