import { Component, ElementRef, AfterViewInit, ViewChild, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { SwiperContainer } from 'swiper/element/bundle';
import { SwiperOptions } from 'swiper/types';
import { NgFor } from '@angular/common';

@Component({
  selector: 'app-revolution-slider',
  standalone: true,
  imports: [NgFor],
  templateUrl: './revolution-slider.component.html',
  styleUrl: './revolution-slider.component.css',
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})


export class RevolutionSliderComponent implements AfterViewInit {
  @ViewChild('swiper') swiperRef!: ElementRef<SwiperContainer>;

    slides = [
      {
        img: 'assets/img/imagen-cabecera.jpg',
        title: 'Gestión Energética',
        subtitle: 'Optimización y eficiencia',
        delay: 2500,
      },
      {
        img: 'assets/img/bosque-iberdrola.jpg',
        title: 'Mantenimiento',
        subtitle: 'Servicios confiables',
        delay: 2500,
      },
      {
        img: 'assets/img/archivo-guadalajara.jpg',
        title: 'Eficiencia Energética',
        subtitle: 'Energía sostenible',
        delay: 2500,
      },
      {
        img: 'assets/img/imagen-cabecera.jpg',
        title: 'Ingeniería y Proyectos',
        subtitle: 'Soluciones innovadoras',
        delay: 2500,
      }
    ];


   
    ngAfterViewInit(): void {
      const swiperParams: SwiperOptions = {
        slidesPerView: 1,
        loop: true,
        autoplay: {
          delay: 2500,
          disableOnInteraction: false
        },
        navigation: true,
        // scrollbar: { draggable: true },
        effect: 'fade',
        fadeEffect: { crossFade: true },
        speed: 1000
      };
      
  
      Object.assign(this.swiperRef.nativeElement, swiperParams);
      (this.swiperRef.nativeElement as any).initialize();

    }
  }


