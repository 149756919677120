<!-- Navigation -->
    <header class="nav-type-2">
        <nav class="navbar navbar-static-top">
            <div class="navigation">
                <div class="container-fluid relative">                   
                    <div class="row navbar-header">
                        <div class="col navbar-header-toggle">
                           <!-- Logo -->
                           <div class="logo-container">
                                <div class="logo-wrap local-scroll">
                                    <a href="/">
                                        <picture>
                                            <source srcset="assets/img/logoCentralGas.webp" type="image/webp" alt="Logo CentralGas">
                                            <source srcset="assets/img/logoCentralGas.png" type="image/png" alt="Logo CentralGas"> 
                                            <img src="assets/img/logoCentralGas.png" alt="Logo CentralGas"/>
                                        </picture>
                                    </a>
                                </div>
                                
                                <div class="logo-wrap local-scroll-iberdrola">
                                    <a href="https://www.iberdrola.es" target="_blank" rel="noopener noreferrer">
                                        <picture>
                                            <source srcset="assets/img/socioColaborador-iberdrola.webp" type="image/webp" alt="Colaborador Iberdrola">
                                            <source srcset="assets/img/socioColaborador-iberdrola.png" type="image/png" alt="Colaborador Iberdrola"> 
                                            <img src="assets/img/socioColaborador-iberdrola.png" alt="Colaborador Iberdrola"/>
                                        </picture>
                                    </a>
                                </div>
                            </div>   

                                <div class="toggle-container d-none d-md-block d-xl-none">
                                <button type="button" class="navbar-toggle"   (click)="toggleDropdown()">
                                <span class="sr-only">Toggle navigation</span>
                                <span class="icon-bar"></span>
                                <span class="icon-bar"></span>
                                <span class="icon-bar"></span>
                                </button>
                            </div>
                        </div>
                        <!-- end navbar-header -->

                        <div class="col-md-12 nav-wrap text-md-end"
                        [ngClass]="{'nav-hidden': !isMenuOpen}">
                             <div [ngClass]="{'d-md-flex': true, 'd-none': isMenuOpen, 'mostrar-toggle': !isMenuOpen}" id="navbar-collapse"> 

                                <ul class="nav navbar-nav">
                                    <!-- <li class="<% if(active == 'quienesSomos'){%>active<%} %>"> -->
                                    <li class="quienesSomos">
                                        <a href="#quienesSomos" accion="desplazarse" destino="#quienesSomos"> ¿Quiénes somos?</a>
                                    </li>
                                    
                                    <!-- <li class="<% if(active == 'servicios'){%>active<%} %>"> -->
                                    <li class="servicios">
                                        <a href="#servicios"  accion="desplazarse" destino="#servicios">Servicios</a>
                                    </li>

                                    <!-- <li class="<% if(active == 'trabajos'){%>active<%} %>"> -->
                                    <li class="trabajos">
                                        <a href="#trabajos"  accion="desplazarse" destino="#trabajos"> Trabajos</a>
                                    </li>
                                        
                                    <!-- <li class="<% if(active == 'instalacionesDomesticas'){%>active<%} %>"> -->
                                    <li class="instalacionesDomesticas">
                                        <a href="#instalacionesDomesticas"  accion="desplazarse" destino="#instalacionesDomestiocas"> Instalaciones Domésticas</a>
                                    </li>

                                    <!-- <li class="<% if(active == 'contacto'){%>active<%} %>"> -->
                                    <li class="contacto">
                                        <a href="#contacto"  accion="desplazarse" destino="#contacto">Contacto</a>
                                        <!-- <i class="fa fa-envelope-o"></i> -->
                                    </li>

                                    <!--
                                    <li class="language-selector" (click)="toggleDropdownLanguage()" >
                                        <a>                                            
                                            <span id="language-button">                                            
                                                <img class="flag" loading="lazy" src="assets/img/flags/es.png" alt="Flag language"/> 
                                                <svg viewBox="0 0 16 16"><polygon points="3,5 8,11 13,5"></polygon></svg>
                                            </span>
                                            <div class="language-list" [ngClass]="{ 'show': isOpenLanguage }">
                                                <div tag="es" (click)="selectLanguage('es')"> 
                                                    <img class="flag" loading="lazy" src="assets/img/flags/es.png" alt="Flag español"/> Español
                                                </div>                                              
                                                <div tag="cat" (click)="selectLanguage('cat')"> 
                                                    <img class="flag" loading="lazy" src="assets/img/flags/cat.png" alt="Flag catalán"/> Catalán
                                                </div>
                                                <div tag="eus" (click)="selectLanguage('eus')"> 
                                                    <img class="flag" loading="lazy" src="assets/img/flags/eus.png" alt="Flag euskera"/> Euskera
                                                </div>
                                                <div tag="en" (click)="selectLanguage('en')"> 
                                                    <img class="flag" loading="lazy" src="assets/img/flags/en.png" alt="Flag inglés"/> Inglés
                                                </div>
                                            </div>
                                        </a>                                     
                                    </li> 
                                    -->
                                    <div class="logo-wrap local-scroll-sunflower">
                                        <a href="https://www.sunflowerenergias.es" target="_blank" rel="noopener noreferrer">
                                            <picture>
                                                <source srcset="assets/img/logoSunflower.webp" type="image/webp" alt="Logo CentralGas">
                                                <source srcset="assets/img/logoSunflower.png" type="image/png" alt="Logo CentralGas"> 
                                                <img src="assets/img/logoSunflower.png" alt="Logo Sunflower"/>
                                            </picture>
                                        </a>
                                    </div>
                                </ul>
                            </div>
                        </div>
                     
                        <!-- end col -->
                    </div>
                    <!-- end row -->
                </div>
                <!-- end container -->
            </div>
            <!-- end navigation -->
        </nav>
        <!-- end navbar -->
    </header>
<!-- end navigation -->