import { Component, OnInit } from '@angular/core';
import { PoliticaDeCookiesComponent } from '../politica-de-cookies/politica-de-cookies.component';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-aviso-legal',
  standalone: true,
  imports: [
    PoliticaDeCookiesComponent
  ],
  templateUrl: './aviso-legal.component.html',
  styleUrl: './aviso-legal.component.css'
})
export class AvisoLegalComponent implements OnInit { 
  

  constructor(private readonly meta: Meta, private readonly title: Title) { }

  ngOnInit(): void {
    this.title.setTitle('Aviso Legal - Centralgas');
    this.meta.addTag({ name: 'description', content: 'Consulta el Aviso Legal de Centralgas del grupo Sunflower Energías. Conoce la información legal sobre el uso del sitio web, derechos de autor, protección de datos y condiciones de servicio.' });
  }
}

  


