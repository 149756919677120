

                            <!-- 7. POLITICA DE COOKIES -->
                         
                                <p class="mt-0"><strong>¿Qué son las cookies?</strong></p>
                                <p>Este sitio web utiliza cookies y/o tecnologías similares que almacenan y recuperan información cuando navegas. En general, estas tecnologías pueden servir para finalidades muy diversas, como, por ejemplo, reconocerte como usuario, obtener información sobre tus hábitos de navegación, o personalizar la forma en que se muestra el contenido. Los usos concretos que hacemos de estas tecnologías se describen a continuación.</p>

                                <p class="mt-0"><strong>¿Para qué utiliza las cookies esta página web?</strong></p>
                                <p>Esta página web utiliza las cookies para una serie de finalidades, incluidas:</p>
                                
                                <ul class="style-1 mb-20">
                                    <li><strong>Análisis:</strong> Son aquellas que, tratadas por nosotros o por terceros, nos permiten cuantificar el número de usuarios y así realizar la medición y análisis estadístico de la utilización que hacen los usuarios del servicio ofertado. Para ello se analiza su navegación en nuestra página web con el fin de mejorar la oferta de productos o servicios que le ofrecemos.</li>
                                    <li><strong>Cookies personalización:</strong> Son aquellas que permiten recordar información para que el usuario acceda al servicio con determinadas características que pueden diferenciar su experiencia de la de otros usuarios, como, por ej: no volver a mostrarles banner, el idioma, el número de resultados a mostrar cuando el usuario realiza una búsqueda, el aspecto o contenido del servicio en función del tipo de navegador a través del cual el usuario accede al servicio o de la región desde la que accede al servicio, etc.</li>
                                </ul>

                                <p class="mt-0"><strong>¿Quién utiliza las cookies en esta página web?</strong></p>
                                <p>La identificación de quién utiliza las cookies, el tipo de cookies utilizadas y demás detalles, se indica a continuación:</p>

                                <div class="ml-10">
                                    <strong>Cookies propias</strong>
                               
                                    <ul class="style-1 mb-20">
                                        <li><strong>Entidad que utiliza las cookies: </strong>El propietario de esta web.</li>
                                        <li><strong>Cookies:</strong> cookies.</li>
                                        <li><strong>Propósito: </strong>Personalizar puntos de la navegación como por ejemplo no mostrar el mensaje de aceptación de cookies en caso de que ya se haya aplicado dicho consentimiento.</li>
                                        <li><strong>Periodo de conservación:</strong> 1 año.</li>
                                    </ul>
                                </div>

                                <div class="ml-10">
                                    <strong>Cookies de terceros</strong>
                                    
                                    <ul class="style-1 mb-20">
                                        <li><strong>Entidad que utiliza las cookies:</strong> Google Analytics.</li>
                                        <li><strong>Propósito:</strong> Recoger información sobre la navegación de los usuarios por el sitio con el fin de conocer el origen de las visitas y otros datos similares a nivel estadístico.</li>
                                        <li>
                                            <strong>Más información:</strong> 
                                            <a href="https://developers.google.com/analytics/devguides/collection/analyticsjs/cookie-usage" target="_blank" class="enlace">Google Analytics</a> 
                                            y 
                                            <a href="http://www.google.com/intl/es/privacy.html" target="_blank" class="enlace">Centro de privacidad de Google</a>
                                        </li>
                                    </ul>
                                </div>

                                <p>Nota: Las cookies de tipo “Propias” son utilizadas sólo por el propietario de esta web y las cookies “De terceros” son utilizadas, también, por el prestador del servicio que está detallado la tabla anterior.</p>

                                <!-- DESARROLLO ACEPTACION POLITICA DE COOKIES -->

                                <div id="aceptacion container" class="aceptacion">
                                    <h6>USTED PERMITE</h6>
                                    <div class="row">
                                        <div class="col-sm-10">
                                            <p>Personalización: La recogida y el tratamiento de información sobre su uso de este servicio por usted para personalizar la forma en la que se muestra el contenido en la web ej: no volver a mostrarles los banner o mensajes de inicio ya mostrados, aceptados y/o aplicados que se considere innecesaria su reiteración.</p>
                                        </div>
                                        <div class="col-sm-2 text-right">
                                            <label class="switch mt-10 mr-10">
                                                <input id="chkCPState" type="checkbox" (change)="onPersonalizacionStateChange($event)">
                                                <span class="slider round"></span>
                                            </label>
                                        </div>
                                    </div>

                                    <div class="row">
                                        <div class="col-sm-10">
                                            <p>Analíticas: Recoger información sobre la navegación de los usuarios por el sitio web con el fin de conocer el origen de las visitas y otros datos similares a nivel estadístico con el fin de mejorar los servicios.</p>
                                        </div>
                                        <div class="col-sm-2 text-right">
                                            <label class="switch mt-10 mr-10">
                                                <input id="chkCAState" type="checkbox" (change)="onAnaliticaStateChange($event)">
                                                <span class="slider round"></span>
                                            </label>
                                        </div>
                                    </div>
                                </div>
<!-- 
                                <div id="barraaceptacion" style="display:none;">
                                    <p>Acepta nuestras cookies para continuar navegando.</p>
                                    <button (click)="ponerCookie('personalizacion')">Aceptar</button>
                                  </div> -->

                                  
                                <!-- DESARROLLO ACEPTACION POLITICA DE COOKIES -->

                                <p class="mt-0"><strong>¿Cómo puedo desactivar o eliminar estas cookies?</strong></p>
                                <p>Puede usted permitir o bloquear las cookies, así como borrar sus datos de navegación (incluidas las cookies) desde el navegador que usted utiliza. Consulte las opciones e instrucciones que ofrece su navegador para ello. Tenga en cuenta que si acepta las cookies de terceros, deberá eliminarlas desde las opciones del navegador.</p>
                                <p>A continuación tiene disponibles los enlaces con la información para gestionar las cookies de los navegadores más utilizados:</p>
                                <ul class="style-1 mb-20">
                                    <li>
                                        <strong>Firefox: </strong><a href="http://support.mozilla.org/es/kb/habilitar-y-deshabilitar-cookies-que-los-sitios-we" target="_blank" class="enlace">http://support.mozilla.org/es/kb/habilitar-y-deshabilitar-cookies-que-los-sitios-we</a>
                                    </li>
                                    <li>
                                        <strong>Chrome: </strong><a href="http://support.google.com/chrome/bin/answer.py?hl=es&answer=95647" target="_blank" class="enlace">http://support.google.com/chrome/bin/answer.py?hl=es&answer=95647</a>
                                    </li>
                                    <li>
                                        <strong>Internet Explorer: </strong><a href="https://support.microsoft.com/es-es/help/278835/how-to-delete-cookie-files-in-internet-explorer" target="_blank" class="enlace">https://support.microsoft.com/es-es/help/278835/how-to-delete-cookie-files-in-internet-explorer</a>
                                    </li>
                                    <li>
                                        <strong>Microsoft Edge: </strong><a href="https://support.microsoft.com/es-es/help/4468242/microsoft-edge-browsing-data-and-privacy-microsoft-privacy" target="_blank" class="enlace">https://support.microsoft.com/es-es/help/4468242/microsoft-edge-browsing-data-and-privacy-microsoft-privacy</a>
                                    </li>
                                    <li>
                                        <strong>Safari: </strong><a href="http://support.apple.com/kb/ph5042" target="_blank" class="enlace">http://support.apple.com/kb/ph5042</a>
                                    </li>
                                    <li>
                                        <strong>Opera: </strong><a href="http://help.opera.com/Windows/11.50/es-ES/cookies.html" target="_blank" class="enlace">http://help.opera.com/Windows/11.50/es-ES/cookies.html</a>
                                    </li>
                                    <li>Otros navegadores: consulte la documentación del navegador que tenga instalado.</li>
                                </ul>

                                <p><strong>Complemento de inhabilitación para navegadores de Google Analytics:</strong> Si desea rechazar las cookies analíticas de Google Analytics en todos los navegadores, de forma que no se envíe información suya a Google Analitys, puede descargar un complemento que realiza esta función desde este enlace: <a href="https://tools.google.com/dlpage/gaoptout" target="_blank" class="enlace">https://tools.google.com/dlpage/gaoptout</a>.
                                </p>

                                <p class="mt-0"><strong>Ejercicio de derechos</strong></p>
                                <p>Puede conocer y ejercer sus derechos en materia de protección de datos accediendo a nuestra 
                                     <a accion="desplazarse" destino="#punto4" href="#aviso-legal" class="enlace"> Política de Privacidad y Aviso Legal.</a>.
                                </p>
                           
                            <!-- END 7. POLITICA DE COOKIES -->