import { Routes } from '@angular/router';
import { HomeComponent } from './pages/home/home.component';
import { CabeceraPoliticasComponent } from './pages/cabecera-politicas/cabecera-politicas.component';


export const routes: Routes = [
    { path: '', component: HomeComponent },
    // { path: 'aviso-legal', component: AvisoLegalComponent },
    // { path: 'politica-de-cookies', component: PoliticaDeCookiesComponent }
    { path: 'aviso-legal', component: CabeceraPoliticasComponent },
    { path: 'politica-de-cookies', component: CabeceraPoliticasComponent }
];
