<app-header/>

<main>
   <!-- Main-wrapper -->
    <div class="main-wrapper oh">
        <router-outlet />
    </div>  
    <!-- end main-wrapper -->
</main>

<app-footer/>