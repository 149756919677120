 <!-- 0. OBJETO Y ACEPTACIÓN -->
 <div>
    <h5>0. OBJETO Y ACEPTACIÓN</h5>

    <p>El presente aviso legal regula el uso del sitio web <strong>https://www.centralgas.es</strong>; (en adelante, LA WEB), del que es titular CENTRAL GAS SL (en adelante, EL PROPIETARIO DE LA WEB).</p>
    <p>La navegación por el sitio web de EL PROPIETARIO DE LA WEB atribuye la condición de usuario del mismo e implica la aceptación plena y sin reservas de todas y cada una de las disposiciones incluidas en este Aviso Legal, que pueden sufrir modificaciones.</p>
    <p>El usuario se obliga a hacer un uso correcto del sitio web de conformidad con las leyes, la buena fe, el orden público, los usos del tráfico y el presente Aviso Legal. El usuario responderá frente a EL PROPIETARIO DE LA WEB o frente a terceros, de cualesquiera daños y perjuicios que pudieran causarse como consecuencia del incumplimiento de dicha obligación.</p>

</div>
<!-- END 0. OBJETO Y ACEPTACIÓN -->

<!-- 1. IDENTIFICACIÓN Y COMUNICACIONES -->
 <div class="mt-30">

    <h5>1. IDENTIFICACIÓN Y COMUNICACIONES</h5>

    <p>EL PROPIETARIO DE LA WEB, en cumplimiento de la Ley 34/2002, de 11 de julio, de servicios de la sociedad de la información y de comercio electrónico, le informa de que:</p>

    <ul class="style-1 mb-20">
        <li>
            <p>Su denominación social es:  <strong>CENTRAL GAS SL.</strong></p>
            <p>Su CIF es: <strong>B79251377</strong></p>
            <p>Su domicilio social está en: <strong>CALLE LYON, 9 - LOCAL 9 - 28030 - MADRID.</strong></p>
        </li>
        <li>
            <p>Inscrita en el Registro Mercantil de Madrid · Tomo 25.819· Folio 81 · Sección 8 · Hoja nº M-465290 · Inscripción 3</p>
         

            <p>Para comunicarse con nosotros, ponemos a su disposición diferentes medios de contacto que detallamos a continuación:</p>                                                                                    
                 <ul class="style-1 mb-20">
                    <li>Teléfono: 914303867</li>
                    <li>Email: centralgas&#64;centralgas.es</li>
                </ul> 
            

            <p>Todas las notificaciones y comunicaciones entre los usuarios y EL PROPIETARIO DE LA WEB se considerarán eficaces, a todos los efectos, cuando se realicen a través de correo postal o cualquier otro medio de los detallados anteriormente.</p>
        </li>
    </ul>

</div> 
<!-- END 1. IDENTIFICACIÓN Y COMUNICACIONES -->

<!-- 2. CONDICIONES DE ACCESO Y UTILIZACIÓN -->
<div class="mt-30">
    <h5>2. CONDICIONES DE ACCESO Y UTILIZACIÓN</h5>

    <p>El sitio web es de acceso son de acceso libre y gratuito.</p>
    <p>El usuario garantiza la autenticidad y actualidad de todos aquellos datos que comunique a EL PROPIETARIO DE LA WEB y será el único responsable de las manifestaciones falsas o inexactas que realice.</p>
    <p>El usuario se compromete expresamente a hacer un uso adecuado de los contenidos y servicios de EL PROPIETARIO DE LA WEB y a no emplearlos para, entre otros:</p>

    <ul class="style-1 mb-20">
        <li>a) Difundir contenidos, delictivos, violentos, pornográficos, racistas, xenófobo, ofensivos, de apología del terrorismo o, en general, contrarios a la ley o al orden público.</li>
        <li>b) Introducir en la red virus informáticos o realizar actuaciones susceptibles de alterar, estropear, interrumpir o generar errores o daños en los documentos electrónicos, datos o sistemas físicos y lógicos de EL PROPIETARIO DE LA WEB o de terceras personas; así como obstaculizar el acceso de otros usuarios al sitio web y a sus servicios mediante el consumo masivo de los recursos informáticos a través de los cuales EL PROPIETARIO DE LA WEB presta sus servicios.</li>
        <li>c) Intentar acceder a las cuentas de correo electrónico de otros usuarios o a áreas restringidas de los sistemas informáticos de EL PROPIETARIO DE LA WEB o de terceros y, en su caso, extraer información.</li>
        <li>d) Vulnerar los derechos de propiedad intelectual o industrial, así como violar la confidencialidad de la información de EL PROPIETARIO DE LA WEB o de terceros.</li>
        <li>e) Suplantar la identidad de otro usuario, de las administraciones públicas o de un tercero.</li>
        <li>f) Reproducir, copiar, distribuir, poner a disposición o de cualquier otra forma comunicar públicamente, transformar o modificar los contenidos, a menos que se cuente con la autorización del titular de los correspondientes derechos o ello resulte legalmente permitido.</li>
        <li>g) Recabar datos con finalidad publicitaria y de remitir publicidad de cualquier clase y comunicaciones con fines de venta u otras de naturaleza comercial sin que medie su previa solicitud o consentimiento.</li>
    </ul>

    <p>Todos los contenidos del sitio web, como textos, fotografías, gráficos, imágenes, iconos, tecnología, software, así como su diseño gráfico y códigos fuente, constituyen una obra cuya propiedad pertenece a EL PROPIETARIO DE LA WEB, sin que puedan entenderse cedidos al usuario ninguno de los derechos de explotación sobre los mismos más allá de lo estrictamente necesario para el correcto uso de la web.</p>
    <p>En definitiva, los usuarios que accedan a este sitio web pueden visualizar los contenidos y efectuar, en su caso, copias privadas autorizadas siempre que los elementos reproducidos no sean cedidos posteriormente a terceros, ni se instalen a servidores conectados a redes, ni sean objeto de ningún tipo de explotación.</p>
    <p>Asimismo, todas las marcas, nombres comerciales o signos distintivos de cualquier clase que aparecen en el sitio web son propiedad de EL PROPIETARIO DE LA WEB, sin que pueda entenderse que el uso o acceso al mismo atribuya al usuario derecho alguno sobre los mismos.</p>
    <p>La distribución, modificación, cesión o comunicación pública de los contenidos y cualquier otro acto que no haya sido expresamente autorizado por el titular de los derechos de explotación quedan prohibidos.</p>
    <p>El establecimiento de un hiperenlace no implica en ningún caso la existencia de relaciones entre EL PROPIETARIO DE LA WEB y el propietario del sitio web en la que se establezca, ni la aceptación y aprobación por parte de EL PROPIETARIO DE LA WEB de sus contenidos o servicios. Aquellas personas que se propongan establecer un hiperenlace previamente deberán solicitar autorización por escrito a EL PROPIETARIO DE LA WEB. En todo caso, el hiperenlace únicamente permitirá el acceso a la home-page o página de inicio de nuestro sitio web, asimismo deberá abstenerse de realizar manifestaciones o indicaciones falsas, inexactas o incorrectas sobre EL PROPIETARIO DE LA WEB, o incluir contenidos ilícitos, contrarios a las buenas costumbres y al orden público.</p>
    <p>EL PROPIETARIO DE LA WEB no se responsabiliza del uso que cada usuario le dé a los materiales puestos a disposición en este sitio web ni de las actuaciones que realice en base a los mismos.</p>
</div>
<!-- END 2. CONDICIONES DE ACCESO Y UTILIZACIÓN -->

<!-- 3. EXCLUSIÓN DE GARANTÍAS Y DE RESPONSABILIDAD -->
<div class="mt-30">

    <h5>3. EXCLUSIÓN DE GARANTÍAS Y DE RESPONSABILIDAD</h5>

    <p>El contenido del presente sitio web es de carácter general y tiene una finalidad meramente informativa, sin que se garantice plenamente el acceso a todos los contenidos, ni su exhaustividad, corrección, vigencia o actualidad, ni su idoneidad o utilidad para un objetivo específico.</p>
    <p>EL PROPIETARIO DE LA WEB excluye, hasta donde permite el ordenamiento jurídico, cualquier responsabilidad por los daños y perjuicios de toda naturaleza derivados de:</p>

    <ul class="style-1 mb-20">
        <li>a) La imposibilidad de acceso al sitio web o la falta de veracidad, exactitud, exhaustividad y/o actualidad de los contenidos, así como la existencia de vicios y defectos de toda clase de los contenidos transmitidos, difundidos, almacenados, puestos a disposición a los que se haya accedido a través del sitio web o de los servicios que se ofrecen.</li>
        <li>b) La presencia de virus o de otros elementos en los contenidos que puedan producir alteraciones en los sistemas informáticos, documentos electrónicos o datos de los usuarios.</li>
        <li>c) El incumplimiento de las leyes, la buena fe, el orden público, los usos del tráfico y el presente aviso legal como consecuencia del uso incorrecto del sitio web. En particular, y a modo ejemplificativo, EL PROPIETARIO DE LA WEB no se hace responsable de las actuaciones de terceros que vulneren derechos de propiedad intelectual e industrial, secretos empresariales, derechos al honor, a la intimidad personal y familiar y a la propia imagen, así como la normativa en materia de competencia desleal y publicidad ilícita.</li>
    </ul>

    <p>Asimismo, EL PROPIETARIO DE LA WEB declina cualquier responsabilidad respecto a la información que se halle fuera de esta web y no sea gestionada directamente por nuestro webmaster. La función de los links que aparecen en esta web es exclusivamente la de informar al usuario sobre la existencia de otras fuentes susceptibles de ampliar los contenidos que ofrece este sitio web. EL PROPIETARIO DE LA WEB no garantiza ni se responsabiliza del funcionamiento o accesibilidad de los sitios enlazados; ni sugiere, invita o recomienda la visita a los mismos, por lo que tampoco será responsable del resultado obtenido. EL PROPIETARIO DE LA WEB no se responsabiliza del establecimiento de hipervínculos por parte de terceros.</p>

</div>
<!-- END 3. EXCLUSIÓN DE GARANTÍAS Y DE RESPONSABILIDAD -->

<!-- 4. POLÍTICA DE PRIVACIDAD -->
 <span id="punto4" class=""></span>
<div class="mt-30">

    <h5>4. POLÍTICA DE PRIVACIDAD</h5>

    <p>A continuación le indicamos los detalles referidos a los distintos tratamientos de datos personales que realizamos en <strong>CENTRAL GAS SL</strong>.</p>

    <h6 class="mt-20">4.1. Tratamiento de los datos personales de potenciales clientes y clientes</h6>
    <p class="mt-0"><strong>Información Básica sobre Protección de Datos</strong></p>
    <ul class="style-1 mb-20">
        <li><strong>Responsable:</strong> CENTRAL GAS SL.</li>
        <li><strong>Finalidad:</strong> Atender las solicitudes recibidas y realizar la prestación de los servicios solicitados, gestión comercial de promociones de su interés y la gestión administrativa, contable y/o fiscal que fuera necesaria.
            Sus datos como clientes serán utilizados para crearle un USUARIO Y CONTRASEÑA a fin de poder tener ACCESO al área de CLIENTES, donde podrá visualizar contenidos de su interés como facturas y otros servicios.</li>
        <li><strong>Legitimación:</strong> Ejecución de un contrato/ consentimiento del interesado/Interés legítimo del responsable.</li>
        <li><strong>Destinatarios:</strong> No se cederán datos a terceros, salvo obligación legal y los necesarios para el ejercicio y desarrollo de la actividad, cómo asesoría ó entidades financieras.</li>
        <li><strong>Transferencias internacionales:</strong> No están previstas transferencias a terceros países.</li>
        <li><strong>Derechos:</strong> tiene Derecho a <strong>Acceder, Rectificar, Suprimir, Limitación del tratamiento, Portabilidad de los datos, Oposición y retirar su consentimiento cuando sea posible.</strong>  Los cuales podrá ejercer dirigiéndose por escrito a la dirección del Responsable del Tratamiento o en el mail indicado en el punto 1, pudiendo retirar su consentimiento en cualquier momento y para la finalidad deseada, debidamente identificado.</li>
    </ul>

    <h6 class="mt-20">4.1.1. Información adicional sobre protección de datos</h6>
    <p class="mt-0"><strong>¿Quién es el responsable del tratamiento de sus datos?</strong></p>
    <ul class="style-1 mb-20">
        <li><strong>Responsable del tratamiento:</strong> CENTRAL GAS SL.</li>
        <li><strong>CIF:</strong> B79251377</li>
        <li><strong>Dirección:</strong> Calle Lyon, 9 - Local 9 - 28030 - MADRID.</li>
        <li><strong>Teléfono:</strong> 914303867</li>
        <li><strong>Email:</strong> centralgas&#64;centralgas.es</li>
    </ul>

    <p><strong>¿Con qué finalidad tratamos sus datos personales?</strong></p>
    <p>Tratamos la información que nos facilitan las personas interesadas con el fin de realizar la gestión administrativa, contable y fiscal de los servicios solicitados, así como el envío de comunicaciones comerciales, inclusive por correo electrónico, sobre nuestros productos y servicios, siempre que sean de su interés.</p>
    <p>Sus datos como clientes serán utilizados para crearle un USUARIO Y CONTRASEÑA a fin de poder tener ACCESO al área de CLIENTES, donde podrá visualizar contenidos de su interés como facturas y otros servicios.</p>
    <p>Puede cancelar ente servicio ó activarlo en cualquier momento, enviando un mail: centralgas&#64;centralgas.es En el caso de que no facilite sus datos personales, no podremos cumplir con las funcionalidades descritas anteriormente.</p>
    <p>No se van a tomar decisiones automatizadas en base a los datos proporcionados.</p>

    <p><strong>¿Por cuánto tiempo conservaremos sus datos?</strong></p>
    <p>Los datos se conservarán mientras se mantenga la relación comercial, cuándo usted solicite la supresión de sus datos y en su caso, durante los años necesarios para cumplir con las obligaciones legales.</p>

    <p><strong>¿Cuál es la legitimación para el tratamiento de sus datos?</strong></p>
    <p>Le indicamos la base legal para el tratamiento de sus datos: Ejecución de un contrato o de medidas precontractuales a petición del interesado: Atender las solicitudes recibidas.</p>
    <p>Consentimiento del interesado: Gestionar las peticiones realizadas por el interesado.</p>

    <p><strong>¿A qué destinatarios se comunicarán sus datos?</strong></p>
    <p>No se cederán datos a terceros, salvo obligación legal, cómo puede ser Administración tributaria y los necesarios para el ejercicio y desarrollo de la actividad, cómo Asesoría ó Entidades financieras.</p>

    <p><strong>¿Transferencias de datos a terceros países? </strong></p>
    <p>No están previstas transferencias a terceros países.</p>

    <p><strong>¿Cuáles son sus derechos cuando nos facilita sus datos?</strong></p>
    <p>Cualquier persona tiene derecho a obtener confirmación sobre si estamos tratando datos personales que les conciernan, o no.</p>
    <p>Las personas interesadas tienen derecho a acceder a sus datos personales, así como a solicitar la rectificación de los datos inexactos o, en su caso, solicitar su supresión cuando, entre otros motivos, los datos ya no sean necesarios para los fines que fueron recogidos.</p>
    <p>En determinadas circunstancias, los interesados podrán solicitar la limitación del tratamiento de sus datos, en cuyo caso únicamente los conservaremos para el ejercicio o la defensa de reclamaciones. Asimismo, en los supuestos legalmente establecidos, tendrá derecho a la portabilidad de sus datos personales.</p>
    <p>En determinadas circunstancias y por motivos relacionados con su situación particular, los interesados podrán oponerse al tratamiento de sus datos. En este caso, se. dejará de tratar los datos, salvo por motivos legítimos imperiosos, o el ejercicio o la defensa de posibles reclamaciones.</p>
    <p>Podrá ejercitar materialmente sus derechos de la siguiente forma: enviando un correo electrónico a centralgas&#64;centralgas.es o una carta a CALLE LYON, 9 - LOCAL 9 - 28030 - MADRID.</p>
    <p>Si ha otorgado su consentimiento para alguna finalidad concreta, tiene derecho a retirar el consentimiento otorgado en cualquier momento, sin que ello afecte a la licitud del tratamiento basado en el consentimiento previo a su retirada.</p>
    <p>En caso de que sienta vulnerados sus derechos en lo concerniente a la protección de sus datos personales, especialmente cuando no haya obtenido satisfacción en el ejercicio de sus derechos, puede presentar una reclamación ante la Autoridad de Control en materia de Protección de Datos competente a través de su sitio web: www.agpd.es.</p>
    
    <p><strong>¿Cómo hemos obtenido sus datos?</strong></p>
    <p>Los datos personales que tratamos proceden de: El propio interesado.</p>

    <h6 class="mt-20">4.2. Tratamiento de los datos de candidatos a un puesto de trabajo</h6>
    <p class="mt-0"><strong>Información Básica sobre Protección de Datos</strong></p>
    <ul class="style-1 mb-20">
        <li><strong>Responsable del tratamiento:</strong> CENTRAL GAS SL</li>
        <li><strong>Legitimación:</strong> Consentimiento del interesado.</li>
        <li><strong>Destinatarios:</strong> No se cederán datos a terceros, salvo obligación legal.</li>
        <li><strong>Transferencias internacionales:</strong> No están previstas transferencias a terceros países.</li>
        <li><strong>Derechos:</strong> tiene Derecho a <strong>Acceder, Rectificar, Suprimir, Limitación del tratamiento, Portabilidad de los datos, Oposición y retirar su consentimiento en cualquier momento. </strong>Los cuales podrá ejercer dirigiéndose por escrito a la dirección del Responsable del Tratamiento: CALLE LYON, 9 - LOCAL 9 - 28030 - MADRID ó por mail centralgas&#64;centralgas.es y para la finalidad deseada, debidamente identificado.</li>
    </ul>

    <h6 class="mt-20">4.2.1. Información adicional sobre protección de datos</h6>
    <p class="mt-0"><strong>¿Quién es el responsable del tratamiento de sus datos?</strong></p>
    <ul class="style-1 mb-20">
        <li><strong>ENTIDAD:</strong>  CENTRAL GAS SL</li>
        <li><strong>CIF :</strong> B79251377</li>
        <li><strong>Dirección:</strong>  CALLE LYON, 9 - LOCAL 9 - 28030 - MADRID.</li>
        <li><strong>Teléfono:</strong> 914303867</li>
        <li><strong>Email:</strong> centralgas&#64;centralgas.es</li>
    </ul>

    <p><strong>¿Con qué finalidad tratamos sus datos personales?</strong></p>
    <p>Tratamos la información que nos facilitan las personas interesadas con el fin de Gestionar los Curriculum Vitae recibidos y realizar los procesos de selección de personal. En el caso de que no facilite sus datos personales, no podremos cumplir con las funcionalidades descritas anteriormente.</p>
    <p>No se van a tomar decisiones automatizadas en base a los datos proporcionados.</p>

    <p><strong>¿Por cuánto tiempo conservaremos sus datos?</strong></p>
    <p>Dos años desde la última interacción ó cuándo usted solicite la supresión de los mismos.</p>

    <p><strong>¿Cuál es la legitimación para el tratamiento de sus datos?</strong></p>
     <p>Le indicamos la base legal para el tratamiento de sus datos:         </p>                           
        <ul class="style-1 mb-20">
            <li>Consentimiento del interesado: Gestionar los Curriculum Vitae recibidos y realizar los procesos de selección de personal</li>
        </ul>
    

     <p><strong>¿A qué destinatarios se comunicarán sus datos?</strong></p>
    <p>No se cederán datos a terceros, salvo obligación legal.</p>

     <p><strong>Transferencias de datos a terceros países</strong></p>
    <p>No están previstas transferencias de datos a terceros países.</p>

    <p><strong>¿Cuáles son sus derechos cuando nos facilita sus datos? </strong></p>
    <p>Cualquier persona tiene derecho a obtener confirmación sobre si en estamos tratando, o no, datos personales que les conciernan.</p>
    <p>Las personas interesadas tienen derecho a acceder a sus datos personales, así como a solicitar la rectificación de los datos inexactos o, en su caso, solicitar su supresión cuando, entre otros motivos, los datos ya no sean necesarios para los fines que fueron recogidos. Igualmente tiene derecho a la portabilidad de sus datos.</p>
    <p>En determinadas circunstancias, los interesados podrán solicitar la limitación del tratamiento de sus datos, en cuyo caso únicamente los conservaremos para el ejercicio o la defensa de reclamaciones.</p>
    <p>En determinadas circunstancias y por motivos relacionados con su situación particular, los interesados podrán oponerse al tratamiento de sus datos. En este caso, dejaremos de tratar los datos, salvo por motivos legítimos imperiosos, o el ejercicio o la defensa de posibles reclamaciones.</p>
    <p>Podrá ejercitar materialmente sus derechos de la siguiente forma: dirigiéndose por escrito a la dirección del Responsable del Tratamiento: CALLE LYON, 9 - LOCAL 9 - 28030 - MADRID ó por mail centralgas&#64;centralgas.es</p>
    <p>Cuando se realice el envío de comunicaciones comerciales utilizando como base jurídica el interés legítimo del responsable, el interesado podrá oponerse al tratamiento de sus datos con ese fin.</p>
    <p>El consentimiento otorgado es para todas las finalidades indicadas cuya base legal es el consentimiento del interesado. Tiene derecho a retirar dicho consentimiento en cualquier momento, sin que ello afecte a la licitud del tratamiento basado en el consentimiento previo a su retirada.</p>
    <p>En caso de que sienta vulnerados sus derechos en lo concerniente a la protección de sus datos personales, especialmente cuando no haya obtenido satisfacción en el ejercicio de sus derechos, puede presentar una reclamación ante la Autoridad de Control en materia de Protección de Datos competente a través de su sitio web: www.aepd.es.</p>

</div> 
<!-- END 4. POLÍTICA DE PRIVACIDAD -->

<!-- 5. PROCEDIMIENTO EN CASO DE REALIZACIÓN DE ACTIVIDADES DE CARÁCTER ILÍCITO -->
<div class="mt-30">
    <h5>5. PROCEDIMIENTO EN CASO DE REALIZACIÓN DE ACTIVIDADES DE CARÁCTER ILÍCITO</h5>

    <p>En el caso de que cualquier usuario o un tercero considere que existen hechos o circunstancias que revelen el carácter ilícito de la utilización de cualquier contenido y/o de la realización de cualquier actividad en las páginas web incluidas o accesibles a través del sitio web, deberá enviar una notificación a EL PROPIETARIO DE LA WEB identificándose debidamente, especificando las supuestas infracciones y declarando expresamente y bajo su responsabilidad que la información proporcionada en la notificación es exacta.</p>
    <p>Para toda cuestión litigiosa que incumba al sitio web de EL PROPIETARIO DE LA WEB, será de aplicación la legislación española, siendo competentes los Juzgados y Tribunales de Madrid (España).</p>
</div>
<!-- END 5. PROCEDIMIENTO EN CASO DE REALIZACIÓN DE ACTIVIDADES DE CARÁCTER ILÍCITO -->

<!-- 6. PUBLICACIONES -->
<div class="mt-30">
    <h5>6. PUBLICACIONES</h5>

    <p>La información administrativa facilitada a través del sitio web no sustituye la publicidad legal de las leyes, normativas, planes, disposiciones generales y actos que tengan que ser publicados formalmente a los diarios oficiales de las administraciones públicas, que constituyen el único instrumento que da fe de su autenticidad y contenido. La información disponible en este sitio web debe entenderse como una guía sin propósito de validez legal.</p>
</div>
<!-- END 6. PUBLICACIONES -->

<!-- 7.POLITICA DE COOKIES -->
<div class="mt-30">
    <h5>7. POLITICA DE COOKIES</h5>
    <app-politica-de-cookies /> 
</div>
<!-- END POLITICA DE COOKIES -->