<div class="col-md-11 mapa">
    <h5 class="nocaps mt-20">CentralGas S.L.</h5>
    <iframe title="Mapa de ubicación" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d382.61111444369084!2d-3.6322569959912254!3d40.40808511205558!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xd42258f6325b6db%3A0x63b2cb99b2e2090f!2sC.%20de%20Cdad.%20de%20%C3%81guilas%2C%202%2C%20Moratalaz%2C%2028030%20Madrid!5e1!3m2!1ses!2ses!4v1728885242539!5m2!1ses!2ses" style="border:0;width:100%; min-height:395px;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade" name="maps"></iframe>
    <div class="widget footer-address-widget text-left">
        
        <h6 class="footer-address nocaps mt-20">
            C. de Cdad. de Águilas, 2
            <br/>
            28030, Madrid
        </h6> 
    </div>
</div>









