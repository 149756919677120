<section id="quienesSomos" class="">
    <div class="container">
        <div class="row">
            <div class="wow fadeInUp" >
                <div class="normal-box">
                    <!-- <h4><%= __('home.que_hacemos.titulo')%></h4>
                    <p style="display: inline;">SUNFLOWER ENERGÍAS, S.L. </p> <h1 class="h3" style="display: inline;"><%= __('home.que_hacemos.p1')%></h1>
                    <p style="display: inline;"><%= __('home.que_hacemos.p2')%></p>
                    <p><%- __('home.que_hacemos.p3')%></p>
                    <p><%- __('home.que_hacemos.p4')%></p>
                    <h6 class="text-center"><%- __('home.que_hacemos.p5')%></h6>
                    <p><%- __('home.que_hacemos.p6')%></p>
                    <p><%- __('home.que_hacemos.p7')%></p> -->

                    <span  class="titulo quienes-somos" >¿QUIÉNES SOMOS?</span>
                    <p style="display: inline;">CENTRALGAS, S.L   </p> <h1 class="h3" style="display: inline;"> es una Empresa de Servicios Energéticos.</h1>
                    <p style="display: inline;"><br>Somos una empresa con más de 30 años de experiencia en el sector energético, autorizada por la Dirección General de Industria de la Comunidad de Madrid desde 1989.</p>
                    <p> Nos especializamos en la ejecución de instalaciones de gas en todo tipo de edificios, incluyendo hospitales, hoteles, colegios, industrias y locales comerciales. A lo largo de nuestra trayectoria, hemos trabajado con empresas constructoras, de climatización, así como con clientes particulares, ofreciendo siempre un servicio personalizado y de calidad.</p>
                    <p>Ofrecemos servicios como empresa de instalaciones llevando a cabo su proyecto, ejecución y control de obra, hasta su puesta en funcionamiento.</p>
                    <span class="titulo texto-principal que-hacemos">¿QUÉ HACEMOS?</span>
                    <h6 class="text-center nos-encargamos">Nos encargamos de todo el proceso relacionado con la instalación de gas, desde el estudio inicial y la valoración conforme a la normativa vigente, hasta la obtención de la acometida a la red pública, legalización y puesta en servicio.</h6>
                    <p>Nuestro compromiso es convertirnos en su empresa instaladora de confianza, brindándole un asesoramiento energético integral y las mejores soluciones adaptadas a sus necesidades.</p>
                    <span  class="titulo texto-principal nuestros-objetivos">NUESTROS OBJETIVOS</span>
                    <p>En CENTRALGAS, trabajamos con un objetivo claro: garantizar la satisfacción de nuestros clientes a través de un servicio profesional y competitivo. Si nos envía sus planos o mediciones, le ofreceremos una propuesta ajustada y eficiente para su proyecto energético.
                    </p>
                </div>
            </div>
        </div>
    </div>
</section>