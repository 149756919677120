import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import { AvisoLegalComponent } from '../aviso-legal/aviso-legal.component';
import { PoliticaDeCookiesComponent } from '../politica-de-cookies/politica-de-cookies.component';
import { CommonModule } from '@angular/common';
import { ContactoComponent } from '../../components/contacto/contacto.component';

@Component({
  selector: 'app-cabecera-politicas',
  standalone: true,
  imports: [
    CommonModule,
    AvisoLegalComponent,
    PoliticaDeCookiesComponent
  ],
  templateUrl: './cabecera-politicas.component.html',
  styleUrl: './cabecera-politicas.component.css'
})
export class CabeceraPoliticasComponent implements OnInit{
  currentRoute: string = '';

  constructor(private router: Router, private route: ActivatedRoute) {}



  ngOnInit(): void {
    this.route.url.subscribe(() => {
      this.currentRoute = this.router.url.replace('/','');
    });
  }
  
}
