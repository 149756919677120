
<div class="tabs services-tabs">
    <ul class="nav nav-tabs">
        <li class="servicio">

            <span class="material-symbols-outlined">{{ icon }}</span>
 
            <div  class="content">
                <h2 class="title">{{ title.toUpperCase() }}</h2>

                <span class="description">{{ description }}</span>
            </div>
        </li>
    </ul>
</div> 
