import { Component } from '@angular/core';
import { CardServiciosIconComponent } from '../card-servicios-icon/card-servicios-icon.component';

@Component({
  selector: 'app-servicios',
  standalone: true,
  imports: [
    CardServiciosIconComponent
  ],
  templateUrl: './servicios.component.html',
  styleUrl: './servicios.component.css'
})
export class ServiciosComponent {

}
