<section id="instalacionesDomesticas" class="bg-light pb-30">
    <div class="container">
        <div class="row">
            <div class="wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.1s">
                <div class="normal-box">
                    <h2 class="text-center bottom-line mb-30 mt-30">INSTALACIONES DOMÉSTICAS</h2>
                    <div class="row rowImagen">
                        <div class="col-md-5 mt-30 imagenes">                            
                            <picture>
                                <source srcset="assets/img/caldera.webp" type="image/webp" alt="Interior caldera">
                                <source srcset="assets/img/caldera.png" type="image/png"  alt="Interior caldera"> 
                                <img id="instalacionesDomesticas" loading="lazy" src="assets/img/caldera.png" alt="Interor caldera">
                            </picture>
                        </div>
                        <div class="col-md-5 mt-30 texto-instalaciones">
                            <p>En CENTRALGAS S.L., realizamos su instalación de gas natural con un proceso ágil y con mínima obra, siempre cumpliendo con la normativa vigente.</p>

                            <p class="mb-30">  Además, le gestionamos todas las bonificaciones vigentes de las compañías distribuidoras de gas natural en la Comunidad de Madrid, como Gas Natural Distribución o Madrileña Red de Gas.</p>
                    
                            <!-- <p class="ventajas-titulo"><strong>Ventajas al contratar el suministro con Gas Natural Servicios:</strong></p>
                            <h6 class="ventajas">Servicio de Mantenimiento Gratuito.</h6>
                            <h6 class="ventajas">Financiación de la instalación y equipamiento de hasta 5 años sin necesidad de nóminas ni avales.</h6>
                            <h6 class="ventajas">Unificación de recibos y asesoramiento de ofertas.</h6> -->
                        </div>
                    </div>
                    <div class="row rowRenovacionCaldera">
                        <div class="col-md-12 renovacion-caldera">
                            <h5>Renovación de Caldera</h5>
                            <p>
                                Trabajamos con primeras marcas para la renovación de calderas. Tramitamos las ayudas disponibles y ofrecemos financiación para facilitar su inversión.
                            </p>
                            <p>
                            Realizamos todos los trámites necesarios para que no tenga que preocuparse por nada.
                            </p>
                            <p>
                                Para más información, llámenos al 914303867 y descubra cómo podemos ayudarle a hacer de su hogar un lugar más cómodo y eficiente.
                            </p>
                        </div>
                        <div class="col rectangulo-marcas ">
                            <ul>
                                <li>
                                    <div class="col bosch">
                                        <img id="bosch" loading="lazy" src="assets/img/bosch-logo.png" alt="Bosch">
                                    </div>
                                </li>
                                <li>
                                    <div class="col saunier">
                                        <img id="saunier" loading="lazy" src="assets/img/saunier-logo.png" alt="Saunier">
                                    </div>
                                </li>
                                <li>
                                    <div class="col baxi">
                                        <img id="baxi" loading="lazy" src="assets/img/baxi-logo.png" alt="Baxi">
                                    </div>
                                </li>
                            </ul>
                        </div> 
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>