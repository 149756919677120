<section>
    <div class="rev_slider_wrapper">
        <div class="rev_slider" id="slider4" data-version="5.0">
                <picture>
                  <source srcset="assets/img/imagen-cabecera.webp" type="image/webp" alt="Imagen Cabecera">
                  <source srcset="assets/img/imagen-cabecera.jpg" type="image/webp" alt="Imagen Cabecera">
                  <img fetchPriority="high" id="imagenCabecera" src="assets/img/imagen-cabecera.jpg" alt="Imagen Cabecera"/>
                </picture>
        </div>
    </div>
</section>