
    <div class="main-wrapper oh">
          <!-- Page Title -->
          <section class="page-title text-center" style="background-image: url(../../../assets/img/firmando.jpg)" aria-label="Imagen cabecera políticas">
            <div class="container relative clearfix">
                <div class="title-holder">
                    <div class="title-text">
                    </div>
                </div>
            </div>
        </section>
        <!-- end page title -->

        <section class="section-wrap blog-single bg-light">
            <div class="container relative">
                <div class="row entry">
        
                    <!-- Page Title -->
                    <div class="row mb-60">
                        <div class="col-md-12 text-center">
                            <h2>{{ currentRoute === 'aviso-legal' ? 'Aviso legal y política de privacidad' : 'Política de Cookies' }}</h2>
                        </div>
                    </div>
                    <!-- end page title -->


                    <div class="wow fadeIn" data-wow-duration="0.5s" data-wow-delay="0.1s">
                        <div class="normal-box">
                            <app-aviso-legal *ngIf="currentRoute === 'aviso-legal'"></app-aviso-legal>
                            <app-politica-de-cookies *ngIf="currentRoute === 'politica-de-cookies'"></app-politica-de-cookies>
                          </div>
                    </div>
                </div>
            </div>
        </section>
      

    </div> <!-- end main-wrapper -->
